const createWaitingRoom = () => {

  const queueAdd = async (str) => {
    const url = process.env.REACT_APP_QUEUE_ADD_URL
    return await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'text/plain',
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
      body: str,
    });
  }

  const queueStatus = async (id) => {
    const url = process.env.REACT_APP_QUEUE_CONSUMER_URL+`/position?id=${id}`
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueReset = async (id) => {
    const url = process.env.REACT_APP_QUEUE_CONSUMER_URL+`/reset?id=${id}`
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueReQueue = async (id) => {
    const url = process.env.REACT_APP_QUEUE_CONSUMER_URL+`/requeue?id=${id}`
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueRemove = async (id) => {
    const url = process.env.REACT_APP_QUEUE_CONSUMER_URL+`/remove?id=${id}`
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueStats = async () => {
    const url = process.env.REACT_APP_QUEUE_CONSUMER_URL
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueWaitingRoom = async () => {
    const url = process.env.REACT_APP_QUEUE_CONSUMER_URL+`/view`
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueAddAdmin = async () => {
    const url = `https://queue-test.game7-workers.workers.dev/simulation?command=add`
    console.log(url)
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueRemoveAdmin = async () => {
    const url = `https://queue-test.game7-workers.workers.dev/simulation?command=remove`
    console.log(url)
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  const queueReQueueAdmin = async () => {
    const url = `https://queue-test.game7-workers.workers.dev/simulation?command=requeue`
    console.log(url)
    return await fetch(url, {
      method: 'GET',
      headers: {
          'Key': process.env.REACT_APP_PUBLIC_KEY,
          'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
        },
    });
  };

  return {
    queueAdd,
    queueStatus,
    queueReset,
    queueRemove,
    queueReQueue,
    queueAddAdmin,
    queueRemoveAdmin,
    queueReQueueAdmin,
    queueWaitingRoom,
    queueStats,
  };

}

export default createWaitingRoom();
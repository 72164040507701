import React, {Fragment, useEffect, useState} from "react";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  UncontrolledTooltip, Modal, ModalHeader, Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {_waitingroom} from "../../_api";
import ChartSection from "./ChartSection";
import WelcomeComp from "./WelcomeComp";

const BasicTable = () => {
  const [addRecordsButton, disableAddRecordsButton] = useState(false);
  const [removeRecordsButton, disableRemoveRecordsButton] = useState(false);
  const [requeueRecordsButton, disableRequeueRecordsButton] = useState(false);
  const [queuedAddress, setQueuedAddress] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [batchModal, setBatchModal] = useState(false);
  const [waitingRoomArray, setWaitingRoomArray] = useState([]);
  const [waitingRoomStats, setWaitingRoomStats] = useState({
    waiting: 0,
    estimated: 0,
    waitingHistory: [],
    estimateHistory: [],
  });

  useEffect(() => {
    getWaitingRoomArray();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getQueueInformation();
    }, 2000);
  }, [])

  const getQueueStats = () => {
    const resp = _waitingroom.queueStats();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((d) => {
        if (d.status === 1) {
          setWaitingRoomStats(prevState => ({
            waiting: d.message.waiting,
            estimated: d.message.estimated,
            waitingHistory: [...prevState.waitingHistory.slice(-20), d.message.waiting],
            estimateHistory: [...prevState.estimateHistory.slice(-20), d.message.estimated]
          }));
        } else {
          console.log("error: ", d);
        }
      })
      .catch((e) => {
        console.log("error: ", e.message);
      });
  };

  const getTopChartData = () => {
    const stats = waitingRoomStats
    //console.log(stats.waitingHistory)
    let istrendingArrow = false;
    let color = '["--bs-success", "--bs-transparent"]';
    let badgeColorA = "success";

    let istrendingArrowChart2 = false;
    let colorChart2 = '["--bs-success", "--bs-transparent"]';
    let badgeColorB = "success";

    const _waA = stats.waitingHistory[0];
    const _waB = stats.waitingHistory.slice(-1);
    if (_waB && (_waA > _waB)) {
      istrendingArrow = true;
      badgeColorA = "danger";
      color = '["--bs-danger", "--bs-transparent"]';
    }

    const _eaA = stats.estimateHistory[0];
    const _eaB = stats.estimateHistory.slice(-1);
    if (_eaB && (_eaA > _eaB)) {
      istrendingArrowChart2 = true
      badgeColorB = "danger";
      colorChart2 = '["--bs-danger", "--bs-transparent"]'
    }

    return [
      {
          id: 1,
          title: "Waiting",
          price: `${stats.waiting}`,
          istrendingArrow,
          perstangeValue: `${_waA}, ${_waB}`,
          bagdeColor: badgeColorA,
          seriesData: [{
              name: "Community Growth",
              data: stats.waitingHistory,
          }],
          color
      },
      {
          id: 2,
          title: "Estimated",
          price: `${stats.estimated}`,
          istrendingArrow: istrendingArrowChart2,
          perstangeValue:  `${_eaA}, ${_eaB}`,
          bagdeColor: badgeColorB,
          seriesData: [{
              name: "Grant Deployment",
              data: stats.estimateHistory,
          }],
          color: colorChart2
      },
      {
          id: 3,
          title: "Gas Fees",
          price: "15.2k ",
          perstangeValue: " 8.41",
          bagdeColor: "success",
          seriesData: [{
              name: "VC Deployment",
              data: [60, 14, 5, 60, 30, 43, 65, 84],
          }],
          color: '["--bs-success", "--bs-transparent"]'
      },
      {
          id: 4,
          title: "Minted",
          price: "124",
          perstangeValue: " 20.63",
          bagdeColor: "danger",
          istrendingArrow: true,
          seriesData: [{
              name: "Partnerships",
              data: [32, 22, 7, 55, 20, 45, 36, 20],
          }],
          color: '["--bs-danger", "--bs-transparent"]'
      },
  ];
  };

  const getQueueInformation = () => {
    setInterval(getQueueStats, 5000);
  }

  const getWaitingRoomArray = () => {
    const resp = _waitingroom.queueWaitingRoom();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((d) => {
        if (d.status === 1) {
          let resp = d.message.slice(0, 300)
          let dt = new Date().getTime()

          // waiting room wait
          if (resp.length === 0) {
            localStorage.clear()
          } else {
            resp.map((v) => {
              const item = localStorage.getItem(v);
              if (!item) {
                // set local store for page reload
                localStorage.setItem(`${v}`, `${dt}`)
              }
            })
          }

          // waiting room array
          setWaitingRoomArray(resp)
        } else {
          console.log("error: ", d);
        }
      })
      .catch((e) => {
        console.log("error: ", e.message);
      });
  };

  const waitingRoomRecordReposition = (e, address) => {
    const resp = _waitingroom.queueReset(address)
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((d) => {
        if (d.status === 1) {
          console.log(d.message)
          getWaitingRoomArray();
        } else {
          console.log("error: ", d);
        }
      })
      .catch((e) => {
        console.log("error: ", e.message);
      });
  }

  const recordRemoveAndRequeue = (e, address) => {
    //e.preventDefault();
    const resp = _waitingroom.queueReQueue(address)
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((d) => {
        if (d.status === 1) {
          // remove from waiting object
          const _t = localStorage.getItem(address)
          if (_t) {
            localStorage.removeItem(address)
          }

          // rerun array
          getWaitingRoomArray();
        } else {
          console.log("error: ", d);
        }
      })
      .catch((e) => {
        console.log("error: ", e.message);
      });
  }

  const recordRemovePermanently = (e, address) => {
    const resp = _waitingroom.queueRemove(address)
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((d) => {
        if (d.status === 1) {
          // remove from waiting objct
          const _t = localStorage.getItem(address)
          if (_t) {
            localStorage.removeItem(address)
          }

          getWaitingRoomArray();
        } else {
          console.log("error: ", d);
        }
      })
      .catch((e) => {
        console.log("error: ", e.message);
      });
  }

  const addToQueueJob = () => {
    const resp = _waitingroom.queueAdd(queuedAddress)
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((d) => {
        if (d.status === 1) {
          console.log(d.message)
          getWaitingRoomArray();
          setAddModal(false);
          setQueuedAddress("");
        } else {
          console.log("error: ", d);
        }
      })
      .catch((e) => {
        console.log("error: ", e.message);
      });
  };

  const getWaitingRoom = () => {
    const wr = waitingRoomArray;
    const latestRecord = [];
    for (let i in wr) {
      const lr = {
        address: wr[i],
        actions: wr[i],
      }
      latestRecord.push(lr);
    }
    return latestRecord
  }

  const setQueueAutoUpdate = () => {
    setInterval(getWaitingRoomArray, 5000);
  };

  const addRecords = () => {
    disableAddRecordsButton(true)
    _waitingroom.queueAddAdmin()
      .then((r) => {
        disableAddRecordsButton(false)
      });
  }

  const removeRecords = () => {
    disableRemoveRecordsButton(true)
    _waitingroom.queueRemoveAdmin()
      .then((r) => {
        disableRemoveRecordsButton(false)
      });
  }

  const requeueRecords = () => {
    disableRequeueRecordsButton(true)
    _waitingroom.queueReQueueAdmin()
      .then((r) => {
        disableRequeueRecordsButton(false)
      });
  }

  const onClickAddRecordModal = () => {
    setAddModal(true);
  };

  const onClickBatchSizeModal = () => {
    setBatchModal(true);
  };

  const createTable = () => {
    let dt = new Date().getTime()
    //console.log(localStorage.length)

    let data = (
      <tr>
        <th scope="row">1</th>
        <td>empty</td>
        <td>empty</td>
      </tr>
    )

    if (waitingRoomArray.length > 0 ) {
      data = waitingRoomArray.map(function(item, i){
        return (
          <tr key={i}>
            <th scope="row">{i + 1}</th>
            <td>{item}</td>
            <td>{ Math.floor((dt - localStorage.getItem(item)) / 1000)}</td>
            <td>
              <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                  <Button
                    onClick={event => waitingRoomRecordReposition(event, item)}
                    className="btn btn-sm btn-soft-success"
                  >
                    <i className="mdi mdi-arrow-up-down" id="repositionRecord" />
                    <UncontrolledTooltip placement="top" target="repositionRecord">
                        RePosition to End of WR
                    </UncontrolledTooltip>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={event => recordRemoveAndRequeue(event, item)}
                    className="btn btn-sm btn-soft-warning"
                  >
                    <i className="mdi mdi-tray-full" id="requeueRecord" />
                    <UncontrolledTooltip placement="top" target="requeueRecord">
                        Remove from WR and ReQue
                    </UncontrolledTooltip>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={event => recordRemovePermanently(event, item)}
                    className="btn btn-sm btn-soft-danger"
                  >
                    <i className="mdi mdi-delete-outline" id="removeRecord" />
                    <UncontrolledTooltip placement="top" target="removeRecord">
                        Remove from WR and Que
                    </UncontrolledTooltip>
                  </Button>
                </li>
              </div>
            </td>
          </tr>
        )
      })
    }

    return (
      <tbody>
      {data}
      </tbody>
    )
  }

  //meta title
  document.title = "Waiting Room | Live Waiting Room Data";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboards" breadcrumbItem="Waiting Room" />
          <ChartSection chartsData={getTopChartData()} />
          <Row>
            <Col xl="12">
              <WelcomeComp
                addRecords={addRecords}
                addRecordsButton={addRecordsButton}
                removeRecords={removeRecords}
                removeRecordsButton={removeRecordsButton}
                requeueRecords={requeueRecords}
                requeueRecordsButton={requeueRecordsButton}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    Record Count: {waitingRoomArray.length < 300 ? waitingRoomArray.length : `${waitingRoomArray.length} (maxed)`}
                  </CardTitle>
                  <CardSubtitle className="card-title-desc">
                    <Fragment>
                      <Row className="mb-2">
                        <Col sm="12">
                          <div className="text-sm-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={getWaitingRoomArray}
                            >
                              <i className="mdi mdi-arrow-all me-1" />
                              Refresh Waiting Room
                            </Button>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-rounded  mb-2 me-2"
                              onClick={onClickAddRecordModal}
                            >
                              <i className="mdi mdi-arrow-up me-1" />
                              Add Record to Queue
                            </Button>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-rounded  mb-2 me-2"
                              onClick={onClickBatchSizeModal}
                            >
                              <i className="mdi mdi-tray-full me-1" />
                              Adjust Queue Batch Size
                            </Button>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-rounded  mb-2 me-2"
                              onClick={setQueueAutoUpdate}
                            >
                              <i className="mdi mdi-arrow-down me-1" />
                              Queue Auto-Update
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Fragment>
                  </CardSubtitle>

                  <div className="table-responsive">
                    <Table className="table table-sm m-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Address</th>
                          <th>Duration</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      {createTable()}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={addModal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setAddModal(!addModal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setAddModal(!addModal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Add to Queue!</h4>
                <p className="text-muted font-size-14 mb-4">
                  Add a single address to the Queue.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="text"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Wallet Address"
                    value={queuedAddress || ""}
                    onChange={(e) => setQueuedAddress(e.target.value)}
                  />
                  <Button
                    color="primary"
                    type="button"
                    id="button-addon2"
                    onClick={() => addToQueueJob()}
                  >
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={batchModal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setAddModal(!batchModal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setBatchModal(!batchModal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-tray-full"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Change Queue Batch Size!</h4>
                <p className="text-muted font-size-14 mb-4">
                  Adjust the Queue Batch Size and Speed <br />(default 25 per second).
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>Batch Size</option>
                    <option value="Active">100 Records</option>
                    <option value="Active">75 Records</option>
                    <option value="Active">50 Records</option>
                    <option value="New">40 Records</option>
                    <option value="New">30 Records</option>
                    <option value="New">25 Records</option>
                    <option value="New">10 Records</option>
                  </select>
                  <span className="font-size-24"> ⇒ </span>
                  <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>Batch Speed</option>
                    <option value="Active">1 Second</option>
                    <option value="Active">2 Seconds</option>
                    <option value="New">3 Seconds</option>
                    <option value="New">4 Seconds</option>
                    <option value="New">5 Seconds</option>
                  </select>
                  <Button
                    color="primary"
                    type="button"
                    id="button-addon2"
                    onClick={() => addToQueueJob()}
                  >
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default BasicTable

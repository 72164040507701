import React from "react"

import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

import { _waitingroom } from "../../_api";

const _d = {
  firstName: "There",
  newStuff: 7
}

const WelcomeComp = (
  {
    addRecords,
    addRecordsButton,
    removeRecords,
    removeRecordsButton,
    requeueRecords,
    requeueRecordsButton,
  }) => {

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="pt-4">
                  <Button
                    disabled={addRecordsButton}
                    type="button"
                    className="btn btn-outline-primary btn-lg btn-link"
                    onClick={() => addRecords()}
                    id="add-records"
                  >
                    Add Records
                  </Button>
                  <Button
                    disabled={removeRecordsButton}
                    type="button"
                    className="btn btn-outline-primary btn-lg btn-link"
                    onClick={() => removeRecords()}
                    id="add-records"
                  >
                    Remove Records
                  </Button>
                  <Button
                    disabled={requeueRecordsButton}
                    type="button"
                    className="btn btn-outline-primary btn-lg btn-link"
                    onClick={() => requeueRecords()}
                    id="add-records"
                  >
                    ReQueue Records
                  </Button>
                  <Button
                    disabled={true}
                    type="button"
                    className="btn btn-outline-primary btn-lg btn-link"
                    onClick={() => console.log('Shut Down')}
                    id="add-records"
                  >
                    Stop Waiting Room
                  </Button>
                  <Button
                    disabled={true}
                    type="button"
                    className="btn btn-outline-primary btn-lg btn-link"
                    onClick={() => console.log('Start')}
                    id="add-records"
                  >
                    Start Waiting Room
                  </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
